import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';
import ClickButton from 'cccisd-click-button';

export default class BrowserTest extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    state = {
        prefix: null,
        errorMessage: null,
    };

    componentDidMount = async () => {
        const encodedError = this.props.match.params.encodedError;

        if (encodedError === null || encodedError.length === 0) {
            window.location = '/';
        }

        const decoded = atob(encodedError);
        const parsed = JSON.parse(decoded);

        let errorMessage = null;
        switch (parsed.error) {
            case 1:
                errorMessage =
                    (parsed.email ? parsed.email : 'Your account') +
                    ' could not be found in Insights. Please inform a teacher or administrator.';
                break;
            case 2:
                errorMessage =
                    'SAML logins are not enabled for this District. Please inform a teacher or administrator.';
                break;
            case 3:
                errorMessage = 'Incomplete SAML configiration. Please inform a teacher or administrator.';
                break;
            case 4:
                errorMessage = 'Invalid SAML configiration. Please inform a teacher or administrator.';
                break;
            default:
                errorMessage =
                    'An unexpected error occurred during SAML uathentication. Please inform a teacher or administrator.';
                break;
        }

        this.setState({
            prefix: parsed.prefix,
            errorMessage,
        });
    };

    render() {
        const { prefix, errorMessage } = this.state;

        if (prefix === null) {
            return <div>loading</div>;
        }

        return (
            <div className={style.wrapper}>
                <div className={style.message}>{errorMessage}</div>
                <div className="row">
                    <div className="col-md-6">
                        <ClickButton
                            className="btn btn-primary"
                            onClick={() => {
                                window.location = '/';
                            }}
                            title="Back to Insights"
                        />
                    </div>
                    <div className="col-md-6">
                        <ClickButton
                            className="btn btn-primary"
                            onClick={() => {
                                window.location = '/api/sso/saml/login/' + prefix;
                            }}
                            title="Back to SAML Login"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
